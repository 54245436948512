import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import HeaderSEO from '../../components/partials/HeaderSEO'
import AboveFooterSection from '../../components/AboveFooterSection'

import Layout from '../../components/Layout'
import { StaticImage } from 'gatsby-plugin-image'

import slack from '../../resources/images/icons/slack.svg'
import phone from '../../resources/images/icons/phone.svg'
import user_single from '../../resources/images/icons/user-single.svg'
import users_single from '../../resources/images/icons/users-single.svg'

import Logos from '../../components/partials/Logos9'

const support = () => {
  const data = useStaticQuery(graphql`
    query SupportSEO {
      allContentfulPages(
        filter: { pageReference: { pageIdentifier: { eq: "Product_Support" } } }
      ) {
        edges {
          node {
            title {
              title
            }
            keywords {
              keywords
            }
            description {
              description
            }
            oGimage {
              fluid(quality: 100) {
                src
              }
            }
          }
        }
      }
    }
  `)

  const pageSEO = data.allContentfulPages.edges[0].node
  return (
    <Layout>
      <HeaderSEO pageSEO={pageSEO} />
      <section className="pt-5 mt-5 pb-5">
        <div className="container-1 ipad-margin-top">
          <div className="d-flex flex-wrap mt-3">
            <div className="col-md-6 pt-0">
              <p className="title breadcrumb-text pt-4">
                <a
                  href="/architecture-and-integrations/"
                  className="title_text"
                >
                  PRODUCT
                </a>{' '}
                /<span className="title_text font-weight-bold">SUPPORT</span>
              </p>
              <h1>Count on Success</h1>
              <div className="pr-5">
                <p className="hero-description-dark pr-5">
                  Make your data journey even smoother with expert and dedicated
                  support offerings
                </p>
              </div>
              <div className="roll-button mt-2">
                <a
                  className="bg-red text-white "
                  href="/contact-us/?s=support"
                  role="button"
                >
                  <span data-hover="Get a quote">Get a quote</span>
                </a>
                <br />
              </div>
            </div>
            <div className="col-md-6 pt-0 ipad-margin-top">
              <StaticImage
                className="img-fluid mx-auto d-block mobile-margin-top-half"
                src="../../resources/images/vectors/undraw_programming_2svr.svg"
                placeholder="tracedSVG"
                alt="Lenses Support"
              />
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container-1 ">
          <div className="d-flex flex-wrap  mt-5 mb-5">
            <div className="col-md-4 mt-5 mobile-text-center">
              <img
                src={slack}
                className="img-icon"
                aria-hidden="true"
                alt="Lenses Slack Community"
              />
              <h2 className="paragraph-title pt-3 pb-1">Community Slack</h2>
              <p className="height-80">
                Direct communication to our engineering, SREs, customer success
                teams. Available to everyone
              </p>
              <a
                href="https://launchpass.com/lensesio"
                className=" support-position-bottom  primary-text"
              >
                Join now <i className="red-pointer-right"></i>
              </a>
            </div>

            <div className="col-md-2 mt-5"></div>
            <div className="col-md-4 mt-5 mobile-text-center">
              <img
                src={phone}
                className="img-icon-phone"
                aria-hidden="true"
                alt="Lenses Support desk"
              />
              <h2 className="paragraph-title pt-3 pb-1">Support Desk</h2>
              <p className="height-80">
                Leverage Lenses’ team of SREs by Zoom, email and Slack
              </p>
              <a
                href="https://support.lenses.io"
                className=" support-position-bottom  primary-text"
              >
                Speak to support <i className="red-pointer-right"></i>
              </a>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container-1 ">
          <div className="d-flex flex-wrap  mt-5 mb-5">
            <div className="col-md-4 mt-5 mobile-text-center">
              <img
                src={user_single}
                className="img-icon"
                aria-hidden="true"
                alt="Lenses Customer Success Manager"
              />
              <h2 className="paragraph-title pt-3 pb-1">
                Customer Success Manager
              </h2>
              <p className="height-80">
                Direct line to a customer success manager to support you with
                your initiatives and to help you maximise the value of Lenses
              </p>
              <a
                href="/contact-us/"
                className=" support-position-bottom  primary-text"
              >
                Talk to our team <i className="red-pointer-right"></i>
              </a>
            </div>

            <div className="col-md-2 mt-5"></div>
            <div className="col-md-4 mt-5 mobile-text-center">
              <img
                src={users_single}
                className="img-icon"
                aria-hidden="true"
                alt="On-Boarding & Education Team"
              />
              <h2 className="paragraph-title pt-3 pb-1">
                On-Boarding & Education Team
              </h2>
              <p className="height-80">
                Hit the ground running with our expert onboarding and education
                team
              </p>
              <a
                href="https://launchpass.com/lenses-community"
                className="support-position-bottom  primary-text"
              >
                Talk to our team <i className="red-pointer-right"></i>
              </a>
            </div>
          </div>
        </div>
      </section>

      <section className="support-margin-top mb-5">
        <div className="container-1">
          <div className="d-flex flex-wrap">
            <Logos />
          </div>
        </div>
      </section>

      <AboveFooterSection
        text={'Ready to get started with Lenses?'}
        ctaText={'Try now for free'}
        ctaLink="/start/"
      />
    </Layout>
  )
}
export default support
